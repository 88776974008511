import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Pageable} from 'src/app/core/models/Pageable';
import {SpinnerService} from 'src/app/services/spinner.service';
import {TransactionService} from 'src/app/services/transaction.service';
import * as FileSaver from 'file-saver';
import {TransactionFeeOutput} from 'src/app/core/models/TransactionFeeOutput';
import {TransactionOutputForExport} from "../../../core/models/TransactionOutputForExport";
import {Tenant} from "../../../core/models/Tenant";
import {StoreService} from "../../../services/store.service";
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-global-transactions',
  templateUrl: './global-transactions.component.html',
  styleUrls: ['./global-transactions.component.scss'],
})
export class GlobalTransactionsComponent implements OnInit, OnDestroy {
  obs: Subscription[] = [];
  pageable: Pageable;
  loading = false;
  months: any = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
  monthsOrdered: any = [];
  monthSelected: any = null;
  monthTrxFeeList: TransactionFeeOutput[] = null;
  monthTrxList: TransactionOutputForExport[] = null;
  tenantUUIDSelected: string;
  tenantSelected: Tenant = null;
  tenantList: Tenant[] = [];
  resetTable = false;
  isSuperAdmin: boolean;

  constructor(
    private transactionSrv: TransactionService,
    private spinnerSrv: SpinnerService,
    private router: Router,
    private storeSrv: StoreService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.loading = true;
    this.spinnerSrv.loadSpinner.next(true);    
    this.getTenantList();
    this.setMonthsFromCurrentDate();
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
        if (this.isSuperAdmin) {
          if (sessionStorage.getItem('tenant-transactions')) {
            this.tenantUUIDSelected = sessionStorage.getItem('tenant-transactions');
          } else {
            this.tenantUUIDSelected = this.tenantList[0].uuid;          
          }  
        } else {
          this.tenantUUIDSelected = localStorage.getItem('tenant');
        }
        this.tenantSelected = this.tenantList.find(te => te.uuid === this.tenantUUIDSelected);        
      }
    });
  }


  changeTenant(event) {
    this.tenantUUIDSelected = event.value;
    sessionStorage.setItem('tenant-transactions', event.value);
    sessionStorage.removeItem('transactions-table');
    this.resetTable = true;
    this.tenantSelected = this.tenantList.find(te => te.uuid === this.tenantUUIDSelected);
  }

  setMonthsFromCurrentDate() {
    const last2OfCurrentYear: string = new Date().getFullYear().toString().slice(-2);
    const last2OfPreviousYear: string = (new Date().getFullYear() - 1).toString().slice(-2);
    const month: number = new Date().getMonth();

    for (let i = month; i >= 0; i--) {
      this.monthsOrdered.push(
        {id: i + 1, name: "Service charge: " + this.months[i] + " " + last2OfCurrentYear}
      );
    }

    for (let i = 11; i > month; i--) {
      this.monthsOrdered.push(
        {id: i + 1, name: "Service charge: " + this.months[i] + " " + last2OfPreviousYear}
      );
    }
  }

  getYearOfMonthSelectedForExcel() {
    const year: number = new Date().getFullYear();
    const month: number = new Date().getMonth() + 1;
    return (this.monthSelected <= month) ? year : year - 1;
  }

  downloadExcel() {
    if (!this.monthSelected) {
      return;
    }
    this.loading = true;
    let year = this.getYearOfMonthSelectedForExcel();
    this.spinnerSrv.loadSpinner.next(true);
    this.obs.push(
      this.transactionSrv.getTransactionsFeeByMonthAndYearAndCommerceId(this.monthSelected, year, null, this.tenantUUIDSelected).subscribe({
        next: (res: TransactionFeeOutput[]) => {
          this.monthTrxFeeList = res;
          let fileName = `${this.tenantSelected.name}-${this.getMonthSelected().name}`;
          this.exportExcel(fileName, this.monthTrxFeeList);
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        }
      })
    );
  }

  downloadExcelTransactions() {
    if (!this.monthSelected) {
      return;
    }
    this.loading = true;
    let year = this.getYearOfMonthSelectedForExcel();
    this.spinnerSrv.loadSpinner.next(true);
    this.obs.push(
      this.transactionSrv.getTransactionsByMonthAndYearAndCommerceId(this.monthSelected, year, null, this.tenantUUIDSelected).subscribe({
        next: (res: TransactionOutputForExport[]) => {
          this.monthTrxList = res;
          let fileName = `Transacciones ${this.tenantSelected.name}-${this.getMonthSelected().name}`;
          this.exportExcel(fileName, this.monthTrxList);
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        },
        error: err => {
          this.spinnerSrv.loadSpinner.next(false);
          this.loading = false;
        }
      })
    );
  }

  exportExcel(nameFile: string, list: any) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(list);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, `${nameFile}`);
    });
  }

  getMonthSelected() {
    return this.monthsOrdered.find(month => month.id === this.monthSelected)
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  back(): void {
    this.router.navigate(['home']);
  }

  ngOnDestroy(): void {
    this.obs.forEach((ob) => {
      ob.unsubscribe();
    });
  }
}
